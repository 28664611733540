import { Box, Button, CircularProgress, Theme, Typography } from "@material-ui/core";
import { AddShoppingCart, ThumbDown, ThumbUp } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import { add, parse, sub } from "date-fns";
import { newKitRepository } from "@echope/echope-store-core/dist/infrastructure/repository";
import { ColdOption, Kit, OrderType, Product } from "@echope/echope-store-core/dist/models";
import { isLoggedInSelector } from "@echope/echope-store-core/dist/store/auth/selectors";
import {
    CartState,
    sendAddCartDeliveryPrice, sendCartUpdateKitChopeId, sendCartUpdateScheduleDate, sendUpdateCartDeliveryAddress, sendUpdateCartItems, sendUpdateCartItemsColdOption, sendUpdateCartObservation, sendUpdateCartPaymentOption
} from "@echope/echope-store-core/dist/store/cart";
import { getFinishedOrder } from "@echope/echope-store-core/dist/store/customer";
import { getCustomerUserSelector } from "@echope/echope-store-core/dist/store/customerUser/selectors";
import { getSavedOrderOperation, sendCleanOrder, sendSaveOrder } from "@echope/echope-store-core/dist/store/order";
import { sendApplyVoucherDiscount, sendFindVoucherAction } from "@echope/echope-store-core/dist/store/voucher";
import { getSavedOperation, getSavedVouchers, getSelectedVoucher } from "@echope/echope-store-core/dist/store/voucher/selectors";
import { productExceedMaxSaleStore } from "@echope/echope-store-core/dist/use-cases/cart/service";
import { isNonNullOrUndefined, isNullOrUndefined } from "@echope/echope-store-core/dist/util/assertions";
import { useFormik } from "formik";
import _ from 'lodash';
import React, { FC } from "react";
import ReactPixel from 'react-facebook-pixel';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import LinkButton from "../../components/button/LinkButton";
import CouponDiscountModal from "../../components/modal/CouponDiscount";
import Info from "../../components/modal/Info";
import Shipping from '../../services/Shipping';
import { AddressRequest } from "../../services/User";
import { getPreferenceSelectedStore } from "../../store/preferences";
import { WebApplicationState } from "../../store/types";
import { currencyFormatter } from "../../util/formatter";
import ChoppKitCard from "./components/ChoppKitCard";
import CouponButton from "./components/CouponButton";
import Delivery from "./components/Delivery";
import LocalizationCard from "./components/LocalizationCard";
import ProductItemCard from "./components/ProductItemCard";
import Summary from "./components/Summary";
import TemperatureToggle from "./components/TemperatureToggle";

export interface FormState {
    choppKit?: number;
    productCold?: ColdOption;
    dateDelivery: Date;
    paymentMethod: number;
    observation: string;
    addressDelivery: AddressRequest;
}

interface ShowSections {
    hasTemperature: boolean;
    hasChoppKit: boolean;
}

// ao manipular formulário, lembrar que os produtos do carrinnho são gerenciados pelo redux.
// usar o CreateOrderRequest no echope-store-core
const CartScreen: FC = () => {
    const styles = useStyles();
    const route = useHistory();

    const [openModalCouponDiscount, setOpenModalCouponDiscount] =
        React.useState(false);
    const [timeDelivery, setTimeDelivery] = React.useState(0);
    const [priceDelivery, setPriceDelivery] = React.useState(0);
    const [showSections, setShowSections] = React.useState<ShowSections>(
        {} as ShowSections
    );
    const [initialValues, setInitialValues] = React.useState<{ address: AddressRequest | null, today: Date }>({ address: null, today: new Date() });
    const [chopeKit, setChopeKit] = React.useState({} as Kit)

    const [modalConfirmation, setModalConfirmation] = React.useState(false)
    const [modalInfo, setModalInfo] = React.useState(false)
    const [modalInfoError, setModalInfoError] = React.useState(false)
    const [modalInfoSuccess, setModalInfoSuccess] = React.useState(false)
    const [modalErrorMsg, setModalErrorMsg] = React.useState('');

    const cartState = useSelector<WebApplicationState, CartState>((state) => {
        return state.cart;
    });
    const isLogged = useSelector(isLoggedInSelector)
    const user = useSelector(getCustomerUserSelector)
    const customerData = useSelector(getCustomerUserSelector)
    const vouchers = useSelector(getSavedVouchers)
    const selectedVoucher = useSelector(getSelectedVoucher);
    const store = useSelector(getPreferenceSelectedStore);
    const voucherLoad = useSelector(getSavedOperation);
    const orderOperation = useSelector(getSavedOrderOperation);
    const finishedOrder = useSelector(getFinishedOrder)
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            choppKit: showSections.hasChoppKit ? 1 : 0,
            productCold: showSections.hasTemperature ? ColdOption.WithIce : undefined,
            dateDelivery: initialValues.today,
            paymentMethod: 0,
            observation: '',
            addressDelivery: initialValues.address || {} as AddressRequest,
        } as FormState,
        enableReinitialize: true,
        onSubmit(data) {
            if (chopeKit.units > 0 && data.choppKit === 1)
                dispatch(sendCartUpdateKitChopeId(chopeKit.id))
            else
                dispatch(sendCartUpdateKitChopeId(0));

            dispatch(sendUpdateCartPaymentOption(data.paymentMethod))
            dispatch(sendUpdateCartDeliveryAddress(data.addressDelivery))
            dispatch(sendCartUpdateScheduleDate(data.dateDelivery))
            dispatch(sendUpdateCartObservation(data.observation))

            dispatch(sendSaveOrder(OrderType.SITE, undefined, store?.store))
        }
    });

    const handleTempToggle = (option: ColdOption) => {
        formik.setFieldValue('productCold', option);
        dispatch(sendUpdateCartItemsColdOption(option))
    };

    const removeProduct = (product: Product) => {
        const index = cartState.items.findIndex(
            (item) => item.product.id === product.id
        );

        if (index > -1) {
            cartState.items.splice(index, 1);
            dispatch(sendUpdateCartItems([...cartState.items]));
            dispatch(sendApplyVoucherDiscount(selectedVoucher, store?.store));
        }
    };

    const handleConfirmOrder = () => {
        const data = formik.values;
        const products = cartState.items;
        let errorMessage = '';
        if (store?.store) {
            const { closeAt, openFrom, maxDaysForSchedule, valueMaxSale, quantityMaxProductSale } = store.store.info;
            const prodMaxSaleLimit = productExceedMaxSaleStore(quantityMaxProductSale, products);

            if (!data.addressDelivery || _.isEmpty(data.addressDelivery))
                errorMessage = 'O endereço de entrega não foi informado. Por favor, selecione um endereço antes de concluir o pedido'
            else if (!products || products.length === 0)
                errorMessage = 'Não é possível fechar o pedido com um carrinho vazio.'
            else if (prodMaxSaleLimit.length > 0)
                errorMessage = `Esta loja possui o limite de ${quantityMaxProductSale} unidade(s) por produto na compra online. Por favor, verifique a quantidade do(s) seguinte(s) produto(s): ${prodMaxSaleLimit.join(', ')}.`
            else if (valueMaxSale !== 0 && cartState.subTotal >= valueMaxSale)
                errorMessage = `Nesta loja não é permitido compras com valor maior ou igual a ${currencyFormatter.format(valueMaxSale)}`
            else if (isNullOrUndefined(data.paymentMethod) || data.paymentMethod === 0)
                errorMessage = 'A forma de pagamento é obrigatória. Por favor, informe-a antes de concluir o pedido'
            else if (data.dateDelivery) {
                const isValidSchedule = !isNullOrUndefined(maxDaysForSchedule)
                    && data.dateDelivery >= add(new Date(), { days: maxDaysForSchedule });

                const isHoursPermitSchedule = data.dateDelivery.getHours() < parse(openFrom, 'HH:mm', new Date()).getHours()
                    && data.dateDelivery.getHours() >= parse(closeAt, 'HH:mm', new Date()).getHours();

                if (data.dateDelivery < sub(new Date(), { minutes: 5 }))
                    errorMessage = 'Só é permitido agendamento com data futura.';
                else if (isValidSchedule)
                    errorMessage = `O período máximo para data de agendamento está em ${maxDaysForSchedule} dia(s). Por favor, informe uma data anterior ao período máximo.`
                else if (isHoursPermitSchedule)
                    errorMessage = `Não é possível realizar agendamentos no período que a loja está fechada. A hora do agendamento pode ser feito das ${openFrom} até ${closeAt}`;
            }
        } else {
            errorMessage = 'Não é possível fechar o pedido pois não há uma loja selecionada';
        }

        if (errorMessage) {
            setModalErrorMsg(errorMessage)
            setModalInfo(true)
            return;
        }

        setModalConfirmation(true)
    }

    React.useEffect(() => {
        if (finishedOrder) {
            const win: any = window;
            const selectedIds = finishedOrder.items.map(item => item.id);
            const quantity = finishedOrder.items.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
            const products = finishedOrder.items.map(p => ({
                id: p.id,
                name: p.name,
                quantity: p.quantity,
                value: p.price,
                currency: 'BRL'
            }));

            ReactPixel.track('Purchase', {
                content_ids: selectedIds,
                content_name: 'order-confirmation',
                num_items: quantity,
                value: finishedOrder.total,
                content_type: 'product',
                currency: 'BRL',
                contents: products
            });

            win.gtag('event', 'conversion', {
                'send_to': 'AW-392165405/BOvICPCJgowDEJ3w_7oB',
                'currency': 'BRL',
                'value': finishedOrder.total,
                'transaction_id': finishedOrder.id
            });
        }
    }, [finishedOrder])

    React.useEffect(() => {
        if (isLogged && customerData) {
            let addressFormatted = {} as AddressRequest;

            if (customerData.addresses.length > 0) {
                const userAddress = customerData.addresses[0];

                addressFormatted = {
                    id: userAddress.id,
                    postalCode: userAddress.postalCode,
                    street: userAddress.street,
                    streetNumber: userAddress.streetNumber,
                    complement: userAddress.complement,
                    neighborhood: userAddress.neighborhood,
                    locality: userAddress.locality,
                    state: userAddress.state,
                }
            }
            setInitialValues({ ...initialValues, address: addressFormatted });
            formik.setFieldValue('addressDelivery', addressFormatted)

            dispatch(sendFindVoucherAction(user?.id))
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged, customerData])

    React.useEffect(() => {
        const fetchKit = async () => {
            if (!store?.store) return
            try {
                const kit = await newKitRepository().find(store.store);
                setChopeKit(kit)
            } catch (err) {
                if (err instanceof Error)
                    console.error(err.message)
            }
        }

        const fetchDelivery = async () => {
            if (!store?.store?.id) return;

            try {
                const response = await Shipping().getDeliveryPrice(store.store.id);
                setPriceDelivery(response.data.fullPrice)
                setTimeDelivery(response.data.units);
            } catch (err) {
                console.log("erro: ****", err);
            }
        }

        const hasChope = cartState.items.some((item) => item.product.isLager);

        setShowSections({
            hasChoppKit: hasChope,
            hasTemperature: cartState.items.some(
                (item) => item.product.hasColdOption
            ),
        });

        fetchDelivery();
        if (store?.store) {
            if (hasChope) fetchKit();

            dispatch(sendAddCartDeliveryPrice(store?.store))
        }
    }, [cartState.items, dispatch, store?.store]);

    React.useEffect(() => {
        if (isNonNullOrUndefined(selectedVoucher)) {
            dispatch(sendApplyVoucherDiscount(selectedVoucher, store?.store));
        }
    }, [dispatch, selectedVoucher, store?.store])

    React.useEffect(() => {
        setModalInfoError(orderOperation.isFailed());
        setModalInfoSuccess(orderOperation.isSuccess())
    }, [orderOperation])

    React.useEffect(() => {
        if (timeDelivery) {
            let dateWithDelivery = add(new Date(), { minutes: timeDelivery + 5 });
            setInitialValues({ ...initialValues, today: dateWithDelivery })
            formik.setFieldValue('dateDelivery', dateWithDelivery)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeDelivery])

    return (
        <section className={styles.container}>

            <LocalizationCard store={store?.store} />

            <fieldset className={styles.listContainer}>
                <Typography variant="h5" component="h3">
                    Lista de Produtos
                </Typography>

                <Box display="flex" alignItems="center" flexDirection="column">
                    {cartState.items.map((item) => (
                        <ProductItemCard
                            key={item.product.id}
                            product={item.product}
                            quantityCart={item.quantity}
                            removeProduct={removeProduct}
                            price={item.price}
                        />
                    ))}
                </Box>

                {!cartState.items.length && (
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        gridGap={10}
                    >
                        <AddShoppingCart fontSize="large" />
                        <Typography>
                            Você ainda não adicionou produtos ao seu carrinho de
                            compras
                        </Typography>
                    </Box>
                )}
            </fieldset>

            <form onSubmit={formik.handleSubmit}>
                {showSections.hasChoppKit && (
                    <ChoppKitCard
                        formik={formik}
                        kit={chopeKit}
                    />
                )}

                {showSections.hasTemperature && (
                    <TemperatureToggle
                        productCold={formik.values.productCold}
                        handleTempToggle={handleTempToggle}
                    />
                )}

                <Delivery
                    timeDelivery={timeDelivery}
                    initialDate={initialValues.today}
                    storeId={store?.store?.id || 0}
                    formik={formik}
                />

                {voucherLoad.isProcessing()
                    ? (<Box display="flex" alignItems="center" gridGap={10}>
                        <p> Verificando se há vouchers </p>
                        <CircularProgress size={20} />
                    </Box>)
                    : (vouchers?.length > 0 &&
                        (<CouponButton onClick={() => setOpenModalCouponDiscount(true)} />))
                }

                <Summary cartState={cartState} deliveryPrice={priceDelivery} />

                <div className={styles.buttonsContainer}>
                    <LinkButton
                        path="/catalog"
                        text="Continuar Comprando"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        size="large"
                        disabled={orderOperation.isProcessing()}
                    />

                    {Boolean(cartState.items.length) && (
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            type="button"
                            onClick={handleConfirmOrder}
                            disabled={orderOperation.isProcessing()}
                        >
                            {orderOperation.isProcessing()
                                ? (<CircularProgress color="primary" size={25} />)
                                : 'Fechar Pedido'
                            }
                        </Button>
                    )}
                </div>
            </form>

            <CouponDiscountModal
                modalCouponDiscount={openModalCouponDiscount}
                setModalCouponDiscount={setOpenModalCouponDiscount}
                vouchers={vouchers}
            />

            <Info
                modalInfo={modalConfirmation}
                setModalInfo={setModalConfirmation}
                title="Confirmação"
                txtCloseBtn="Cancelar"
                txtSuccessBtn="Confirmar"
                successCallback={() => formik.submitForm()}
                isCenter
            >
                <Typography>
                    Todos os dados estão em ordem. <br />
                    Deseja concluir o pedido?
                </Typography>
            </Info>

            <Info
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                title="Oops!"
                txtSuccessBtn="Ok"
            >
                <Typography>
                    {modalErrorMsg}
                </Typography>
            </Info>

            <Info
                modalInfo={modalInfoError}
                setModalInfo={setModalInfoError}
                title="Oops!"
                icon={<ThumbDown color="primary" style={{ fontSize: '80px' }} />}
                txtSuccessBtn="Ok"
                successCallback={() => { dispatch(sendCleanOrder()) }}
            >
                <Typography>
                    {orderOperation.message}
                </Typography>
            </Info>

            <Info
                modalInfo={modalInfoSuccess}
                setModalInfo={setModalInfoSuccess}
                title="Sucesso!"
                icon={<ThumbUp color="primary" style={{ fontSize: '80px' }} />}
                txtSuccessBtn="Ok"
                successCallback={() => {
                    dispatch(sendCleanOrder())
                    route.push('/store/home')
                }}
            >
                <Typography>
                    {/* {orderOperation.message} */}
                    Pedido realizado com sucesso! <br />
                    Caso queira visualizar o pedido enviado acesse o menu "Meus pedidos"
                </Typography>
            </Info>
        </section>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: "relative",
            maxWidth: 1020,
            margin: "20px auto",
        },
        listContainer: {
            margin: "20px 0 20px",
            border: `1px solid ${theme.palette.grey[100]}`,
            "& h3": {
                background: theme.palette.background.default,
                marginTop: -15,
                padding: "0 10px 10px",
                width: 190,
            },
        },
        choppContainer: {
            background: theme.palette.grey[100],
            padding: 15,
            borderRadius: 10,
            display: "flex",
            gap: 10,
            flexFlow: "column nowrap",
        },
        kitTitle: {
            fontWeight: "bold",
            "& span": {
                color: theme.palette.primary.main,
                textTransform: "uppercase",
            },
        },
        buttonsContainer: {
            // position: "fixed",
            // bottom: 0,
            // left: 0,
            // right: 0,
            display: "flex",
            justifyContent: "space-around",
            gap: 20,
            marginTop: 20,
        },
        [theme.breakpoints.down("sm")]: {
            container: {
                padding: "0 10px",
            },
        },
    })
);

export default CartScreen;
